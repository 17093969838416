@import "./colors";
@import "./utilities";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
  font-family: "inter", sans-serif;
  color: $color3;
  font-size: 16px;
  scroll-behavior: smooth;
}

.dark-overlay {
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;

  &.open {
    opacity: 1;
    pointer-events: all;
  }

  @media screen and (min-width: 1221px) {
    opacity: 0;
    pointer-events: none;
  }
}

.hamburger {
  @media screen and (min-width: 1221px) {
    display: none;
  }
}

.auth-container {
  display: flex;
  height: 100vh;
  min-height: 600px;

  &.signup {
    min-height: 960px;
  }

  .right {
    padding: 5% 60px 5%;
    overflow-y: scroll;
  }

  & > * {
    width: 50%;
    flex-grow: 0;

    @media screen and (max-width: 991px) {
      &.left {
        width: 45%;
      }

      &.right {
        width: 55%;
      }
    }
  }

  .circles {
    position: absolute;
  }

  .left {
    position: relative;
    background-color: $color1;
    padding: 50px;
    height: 100%;

    @media screen and (min-width: 1600px) {
      padding: 100px;
    }

    @media screen and (max-width: 991px) {
      padding-left: 30px;
      padding-right: 30px;
    }

    @media screen and (max-width: 794px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    display: flex;
    align-items: center;

    .circles {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 24vw;
    }

    .main {
      position: relative;
      z-index: 1;
      background-color: $color4;
      padding: 58px 50px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 16px;
      backdrop-filter: blur(8px);

      @media screen and (max-width: 991px) {
        padding-left: 40px;
        padding-right: 40px;
      }

      @media screen and (max-width: 840px) {
        padding-left: 30px;
        padding-right: 30px;
      }

      * {
        color: #fff;
      }

      img {
        margin-top: 50px;
        // height: 42vh;
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-height: 890px) {
          max-height: 400px;
        }

        @media screen and (max-height: 800px) {
          max-height: 330px;
        }

        @media screen and (max-width: 1440px) {
          max-width: 300px;
        }

        @media screen and (max-width: 1023px) {
          max-width: 250px;
        }

        @media screen and (max-width: 991px) {
          max-width: 200px;
        }
      }

      h1 {
        max-width: 480px;

        @media screen and (max-width: 1350px) {
          font-size: 40px;
        }

        @media screen and (max-width: 1130px) {
          font-size: 34px;
        }

        @media screen and (max-width: 1030px) {
          font-size: 30px;
        }

        @media screen and (max-width: 920px) {
          font-size: 26px;
        }

        // @media screen and (max-width: 874px) {
        //   font-size: 22px;
        // }

        // @media screen and (max-width: 800px) {
        //   font-size: 18px;
        // }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    padding-left: 60px;
    padding-right: 60px;

    @media screen and (max-width: 1100px) {
      padding-left: 30px;
      padding-right: 30px;
    }

    .circles {
      right: 0;
      top: 0;
      width: 23vw;
    }

    .main {
      width: 100%;
      max-width: 560px;
      margin-left: auto;
      margin-right: auto;

      h2 {
        margin-top: 60px;

        @media screen and (max-width: 991px) {
          font-size: 30px;
        }
      }

      .form {
        margin: 50px 0 0;
      }
    }

    .bottom {
      margin-top: 50px;
    }

    &.signup {
      h2 {
        margin-top: 30px;
      }
    }
  }
}

.project-creation-container {
  max-width: 1284px;
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .main {
    margin-top: 40px;
    border-radius: 10px;
    background-color: $color12;

    .left {
      width: 400px;
      height: 100%;
      padding: 30px;
      flex-shrink: 0;
      float: left;

      @media screen and (max-width: 991px) {
        width: 230px;
      }

      .steps {
        padding-bottom: 50px;
        border-bottom: 1px solid $color7;

        .step {
          margin-bottom: 50px;
          display: flex;
          align-items: center;
          cursor: pointer;

          .img {
            position: relative;
            background-color: $color7;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 20px;
            border: 1px solid transparent;

            &::after {
              content: "";
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: calc(100% + 10px);
              height: 30px;
              width: 1px;
              background-color: $color7;
            }

            img {
              filter: invert(41%) sepia(2%) saturate(1910%) hue-rotate(189deg)
                brightness(101%) contrast(74%);
            }
          }

          &:last-of-type {
            margin-bottom: 0;

            .img {
              &::after {
                display: none;
              }
            }
          }

          .text {
            font-weight: 600;
            color: $color6;
          }

          &.active {
            .img {
              background-color: $color1;

              img {
                filter: invert(99%) sepia(0%) saturate(348%) hue-rotate(268deg)
                  brightness(114%) contrast(100%);
              }
            }

            .text {
              color: $color3;
            }
          }

          &.done {
            .img {
              background-color: transparent;
              border-color: $color1;

              img {
                filter: invert(76%) sepia(17%) saturate(4174%)
                  hue-rotate(335deg) brightness(96%) contrast(89%);
              }
            }

            .text {
              color: $color1;
            }
          }
        }
      }

      .tip {
        padding-top: 30px;

        a {
          margin-top: 10px;
          font-weight: 600;
        }
      }
    }

    .right {
      flex-grow: 1;
      padding: 50px;
      background-color: $color9;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 732px;

      .btn {
        margin-top: 40px;
      }

      .sub-section {
        margin-bottom: 50px;

        &:last-of-type {
          margin-bottom: 0px;
        }

        h3 {
          margin-bottom: 30px;
        }
      }

      .tools {
        .tools-main {
          border: 1px solid $color7;
          border-radius: 5px;
          padding-bottom: 10px;
          margin-top: 10px;

          .tools-main-title,
          .tools-section-title {
            padding-top: 20px;
            padding-bottom: 20px;
          }

          .tools-main-title {
            background-color: $color13;
            padding-left: 20px;
          }

          .tools-section-title,
          .tools-section-item {
            padding-left: 46px;
            padding-right: 46px;
          }

          .tools-section-title {
            background-color: $color11;
          }

          .tools-section-item {
            padding-top: 10px;
            padding-bottom: 10px;

            &,
            * {
              cursor: grab;

              &:active {
                cursor: grabbing;
              }
            }
          }
        }
      }

      .directory {
        .info {
          background-color: $color11;
          padding: 12px 16px;
          border-radius: 5px;
          margin-top: 24px;
          margin-bottom: 30px;
        }

        .table-container {
          border: 1px solid $color7;
          border-radius: 10px;
          width: 100%;
        }

        table {
          width: 100%;
          border-collapse: collapse;
          min-width: 710px;
          word-break: break-word;
          table-layout: fixed;

          thead {
            background-color: $color11;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }

          tr {
            th,
            td {
              padding: 12px;
            }

            th {
              text-align: left;
              font-size: 11px;
            }

            td {
              color: $color6;
              font-size: 10px;

              &:last-child {
                // text-align: right;
                padding-left: 24px;
                padding-right: 24px;
              }
            }
          }
        }
      }
    }
  }
}

.listing-container {
  height: 100vh;

  & > * {
    height: 100%;
  }

  .table-container {
    border: 1px solid $color7;
    border-radius: 10px;

    table {
      border-collapse: collapse;
      width: 100%;

      th,
      td {
        font-size: 14px;
      }

      thead {
        tr {
          background-color: $color17;

          th {
            padding: 13px 10px;
            text-align: left;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 13px 10px;
          }

          &:nth-child(even) {
            background-color: $color9;
          }
          &:nth-child(even):hover {
            background-color: #fdf4eb;
          }
        }
      }
    }
  }

  .grid {
    .grid-card {
      position: relative;
      border: 1px solid $color7;
      border-radius: 10px;
      padding: 30px;
      display: flex;
      align-items: flex-start;
      height: 100%;

      @media screen and (max-width: 1800px) {
        padding-left: 15px;
        padding-right: 15px;
      }

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;

        @media screen and (max-width: 1905px) {
          width: 75px;
          height: 75px;
        }

        @media screen and (max-width: 1800px) {
          width: 50px;
          height: 50px;
        }
      }

      h4 {
        position: relative;
        display: inline-block;
        width: fit-content;
        padding-right: 26px;

        .tag {
          position: absolute;
          top: -10px;
          // right: -26px;
          right: 0;
          width: 20px;
          height: 20px;
          color: #fff;
          background-color: $color1;
          font-size: 10px;
          font-weight: 600;
          border-radius: 50%;
          text-align: center;
          line-height: 2;
        }
      }

      img {
        margin-right: 1rem;
      }

      .badge {
        position: absolute;
        top: 0;
        left: 0;
        border-top-left-radius: 10px;
      }
    }
  }

  .laptop {
    .laptop-card {
      border-radius: 10px;
      border: 1px solid $color7;
      margin-bottom: 10px;

      h4 {
        padding: 10px 20px;
        background-color: $color9;
      }

      .body {
        display: flex;
        padding: 20px;

        @media screen and (max-width: 1760px) {
          flex-direction: column;
        }

        .details {
          position: relative;
          display: flex;
          padding-right: 30px;

          @media screen and (min-width: 1899px) {
            width: 418px;
          }

          &::after {
            position: absolute;
            content: "";
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            height: calc(100% - 8px);
            border-right: 1px solid $color7;

            @media screen and (max-width: 1760px) {
              display: none;
            }
          }

          .text {
            & > div {
              display: flex;
              margin-bottom: 10px;

              .left {
                width: 70px;
                margin-right: 10px;
              }
            }
          }
        }

        .stats {
          flex-grow: 1;

          @media screen and (min-width: 1761px) {
            padding-left: 30px;
          }

          @media screen and (max-width: 1760px) {
            margin-top: 20px;
          }

          .progress {
            height: 10px;
            width: 100%;
            border-radius: 20px;
            overflow: hidden;

            .progress-bar {
              height: 100%;
              background-color: $color20;
            }
          }

          .legend {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 30px;

            .item {
              display: flex;
              align-items: center;

              .clr {
                border-radius: 4px;
                width: 20px;
                height: 20px;
                display: inline-block;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }

  & > .left {
    width: 258px;
    flex-shrink: 0;
    background-color: $color15;
    float: left;

    @media screen and (max-width: 1399px) {
      width: 200px;
    }

    @media screen and (max-width: 1220px) {
      position: fixed;
      z-index: 5;
      left: -258px;

      &.open {
        left: 0;
      }
    }

    .header {
      padding: 15px 20px;
      border-bottom: 1px solid $color7;
      height: 100px;

      img {
        width: 100%;
      }
    }

    .body {
      padding: 20px;
      border-bottom: 1px solid $color7;
    }

    .foot {
      padding: 20px;
    }
  }

  .right {
    flex-grow: 1;

    .head {
      padding: 30px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $color7;

      .settings-btn {
        background-color: $color15;
        width: 50px;
        height: 50px;
        border-radius: 4px;
        border: none;
        margin-right: 10px;
        flex-shrink: 0;

        @media screen and (max-width: 1500px) {
          width: 30px;
          height: 30px;
        }

        img {
          width: 18px;
        }
      }

      @media screen and (max-width: 1439px) {
        padding-left: 15px;
        padding-right: 15px;
      }

      .nav {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        margin-left: 0.5rem;

        & > * {
          margin: 0 15px;

          @media screen and (max-width: 1250px) {
            margin: 0 5px;
          }
        }
        .options {
          margin: 0;

          & > * {
            margin: 0 15px;
            cursor: pointer;

            @media screen and (max-width: 1250px) {
              margin: 0 5px;
            }
          }
        }

        .input-card {
          width: 180px;
          background-color: $color9;
        }
      }
    }

    & > .body {
      & > .container-fluid > {
        .row {
          & > * {
            height: calc(100vh - 100px - 64px);
            overflow-y: auto;
            @extend %vertical-scrollbar;

            @media screen and (min-width: 1500px) {
              &.col-lg-8 {
                width: 70%;
              }

              &.col-lg-4 {
                width: 30%;
              }
            }
          }
        }
      }

      .body-main {
        padding: 30px;

        @media screen and (max-width: 1439px) {
          padding-left: 15px;
          padding-right: 15px;
        }

        .top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid $color7;
          padding-bottom: 30px;

          @media screen and (max-width: 1499px) {
            flex-direction: column;

            & > * {
              margin: 10px 0;
            }
          }
        }

        .main {
          padding-top: 30px;
        }

        .section {
          margin-bottom: 30px;

          .section-title {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h4 {
              font-size: 20px;
            }
          }
        }

        .filter {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media screen and (max-width: 1499px) {
            flex-direction: column;

            & > * {
              margin: 5px 0;
            }
          }

          .search {
            display: flex;
            align-items: center;

            @media screen and (max-width: 1299px) {
              flex-direction: column;
              & > * {
                margin: 10px 0;
              }
            }

            & > * {
              margin-right: 20px;
            }
          }

          .more-filters {
            display: flex;
            align-items: center;
            cursor: pointer;

            & > * {
              margin-left: 10px;
              cursor: pointer;
            }

            img {
              filter: invert(55%) sepia(1%) saturate(2134%) hue-rotate(206deg)
                brightness(76%) contrast(84%);

              &.active {
                filter: invert(63%) sepia(88%) saturate(977%) hue-rotate(339deg)
                  brightness(100%) contrast(82%);
              }
            }
          }
        }
      }

      .logs-container {
        background-color: $color9;
        // height: 100%;
        padding: 20px;

        .main-title {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media screen and (max-width: 1199px) {
            flex-direction: column;
            align-items: flex-start;

            & > * {
              margin: 10px 0;
            }
          }
        }

        .log-items {
          margin-top: 20px;

          .log-item {
            border-radius: 4px;
            background-color: #fff;
            padding: 1rem;
            margin-bottom: 5px;

            .desc {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}

.footer {
  background-color: $color9;
  border-top: 1px solid $color7;
  padding: 20px;
  text-align: center;
  margin-top: 50px;
  color: $color6;
  font-size: 14px;
  height: 64px;
}

.portfolio {
  .table-container {
    table {
      min-width: 987px;

      tbody {
        td {
          padding-top: 25px;
          padding-bottom: 25px;
        }
      }
    }

    tr {
      td {
        &:nth-child(3) {
          width: 180px;
        }

        &:nth-child(4) {
          min-width: 105px;
        }

        &:nth-child(8) {
          width: 180px;
        }
      }
    }
  }
}

.project {
  table.team {
    min-width: 995px;
  }

  table.open-items {
    min-width: 1100px;
  }

  .card {
    .chart-container {
      position: relative;
      margin-top: 20px;

      &::after {
        position: absolute;
        content: "";
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        left: 20px;
        top: 20px;
        bottom: 20px;
        right: 20px;
        background-color: #fff;
        border-radius: 50%;
      }
    }

    .do-legend {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      & > * {
        margin-right: 0.5rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .clr {
        width: 20px;
        height: 20px;
        border-radius: 4px;
      }

      .text {
        font-size: 13px;
        margin-top: 8px;
      }

      .overdue {
        .clr {
          background-color: $color20;
        }
      }

      .nextSeven {
        .clr {
          background-color: $color21;
        }
      }

      .greaterThanSeven {
        .clr {
          background-color: $color22;
        }
      }
    }
  }
}

.meetings {
  table.meetings {
    tbody {
      // td {
      //   &:nth-child(1) {
      //     min-width: 180px;
      //   }

      //   &:nth-child(2) {
      //     min-width: 60px;
      //   }

      //   &:nth-child(3) {
      //     min-width: 460px;
      //   }

      //   &:nth-child(4) {
      //     min-width: 110px;
      //   }

      //   &:nth-child(5) {
      //     min-width: 120px;
      //   }

      //   &:nth-child(6) {
      //     min-width: 140px;
      //   }

      //   &:nth-child(7) {
      //     min-width: 100px;
      //   }

      //   &:nth-child(8) {
      //     min-width: 90px;
      //   }

      //   &:nth-child(9) {
      //     min-width: 90px;
      //   }

      //   &:nth-child(10) {
      //     min-width: 130px;
      //   }
      // }
    }
  }
}

.create-meeting,
.edit-meeting {
  .form {
    max-width: 650px;

    label {
      margin-bottom: 4px;
    }

    input,
    select,
    .custom-select-container {
      background-color: $color9 !important;
    }

    .calender {
      top: 30px;
    }
  }
}

.daily-log,
.daily-log-calender {
  .top {
    min-height: 86px;
  }
}

.daily-log {
  .card-head {
    @media screen and (max-width: 1099px) {
      flex-direction: column;

      .cal-nav {
        margin-top: 10px;
      }
    }
  }

  & > .body {
    .body-main {
      .top {
        flex-direction: row !important;
        border: 0 !important;
      }
    }
  }

  table {
    input,
    .selected-text {
      background-color: $color9;
    }

    label {
      display: flex;
      align-items: center;
      cursor: pointer;

      .clip {
        margin-right: 10px;
      }

      .text {
        font-size: 14px;
        color: $color1;
        text-decoration: underline;
      }
    }

    tr {
      td {
        &:nth-child(1) {
          min-width: 240px;
        }

        &:nth-child(2) {
          min-width: 120px;
        }

        &:nth-child(3) {
          min-width: 120px;
        }

        &:nth-child(4) {
          min-width: 120px;
        }

        &:nth-child(5) {
          min-width: 240px;
        }

        &:nth-child(6) {
          min-width: 240px;
        }

        &:nth-child(7) {
          min-width: 256px;
        }
      }
    }
  }
}

.daily-log-calender {
  .calender-container {
    display: flex;
    border: 1px solid $color7;
    border-radius: 10px;

    .calender-left > .header,
    .calender-right {
      padding: 30px;
    }

    .calender-left {
      flex-grow: 1;
      border-right: 1px solid $color7;
    }

    .calender-right {
      width: 480px;
      flex-shrink: 0;

      .collapse {
        margin-bottom: 5px;

        .collapse-title {
          background-color: $color9;
          padding: 24px 20px;
          border-radius: 4px;

          h4 {
            font-size: 14px;
          }
        }

        .collapse-body {
          padding: 24px 20px;
        }
      }
    }
  }
}

.error {
  font-size: 11px;
  color: #dc3545;
  margin-top: 5px;
}

.btn-auth {
  max-width: 150px;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.drawings {
  .card-left {
    display: flex;
    flex-direction: column;

    .card-nav {
      .nav-item {
        &::after {
          // content: url(../vectors/menu.svg) !important;
          // transform: rotate(0deg) translateY(-50%);
          display: none;
        }
      }
    }

    .sets {
      padding: 12px;
      flex-grow: 1;

      .set {
        background-color: $color24;
        border: 1px solid $color25;
        border-radius: 4px;
        padding: 12px;
        margin-bottom: 10px;

        .set-head {
          padding-bottom: 8px;
          border-bottom: 1px solid $color7;

          img {
            width: 16px;
          }
        }

        .set-body {
          padding: 8px 0;
        }

        .set-foot {
          padding: 8px 0 0;
          border-top: 1px solid $color7;
        }
      }
    }

    .reports {
      background-color: $color9;
      padding: 12px;
      border-top: 1px solid $color7;

      .report {
        margin-bottom: 12px;
      }
    }
  }

  table {
    tr {
      th,
      td {
      }

      th {
        .checkmark {
          border-color: $color23;
        }
      }

      td {
        &:nth-child(2) {
          min-width: 120px;
        }

        &:nth-child(3) {
          min-width: 120px;
        }

        &:nth-child(4) {
          min-width: 80px;
        }

        &:nth-child(5) {
          min-width: 120px;
        }

        &:nth-child(6) {
          min-width: 120px;
        }

        &:nth-child(7) {
          min-width: 340px;
        }

        &:nth-child(8) {
          min-width: 195px;
        }

        .btn {
          height: 30px;
        }
      }
    }
  }

  .drawing-grid {
    .drawing-grid-item {
      position: relative;
      padding: 20px;
      border: 1px solid $color7;
      border-radius: 4px;
      width: 246px;

      @media screen and (max-width: 1080px) {
        margin-left: auto;
        margin-right: auto;
      }

      img {
        width: 100%;
        height: 204px;
        object-fit: contain;
      }

      .custom-checkbox-container {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        .checkmark {
          position: absolute;
          top: 18px;
          left: 10px;
        }
      }

      &:hover {
        box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);

        .custom-checkbox-container {
          opacity: 1;
        }
      }
    }
  }
}

.landing-container {
  .section {
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 150px 0;

    @media screen and (min-width: 576px) {
      padding-left: 5rem;
      padding-right: 5rem;
    }

    @media screen and (max-width: 1220px) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  .page-container {
    max-width: 1384px;
    margin-left: auto;
    margin-right: auto;
  }

  .main-title {
    margin-bottom: 50px;
    text-align: center;
    max-width: 840px;
    margin-left: auto;
    margin-right: auto;

    h2 {
      margin-bottom: 20px;
    }
  }

  p {
    color: $color6;
    line-height: 1.75;
    font-weight: 400;
  }

  .appbar {
    .section {
      margin: 0;
    }

    .header {
      background-color: $color1;

      * {
        color: #fff;
      }

      .contact {
        display: flex;

        a {
          display: flex;
          padding: 16px 0;
          align-items: center;
          img {
            margin-right: 15px;
          }
        }
      }

      .selected-text {
        padding: 0;
        border: 0;
        padding-right: 40px;

        &::after {
          content: url(../vectors/select-arrow-white.svg);
        }
      }

      .select-options {
        top: calc(100% + 0.75rem);

        .select-option {
          color: $color3;
        }
      }
    }

    .navbar .page-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        img {
          @media screen and (max-width: 1250px) {
            width: 120px;
          }
        }
      }

      .nav-container {
        display: flex;
        align-items: center;

        .nav {
          margin-right: 54px;
          gap: 30px;
          display: flex;

          @media screen and (max-width: 1250px) {
            margin-right: 30px;
            gap: 15px;
          }

          @media screen and (max-width: 1100px) {
            margin-right: 20px;
            gap: 10px;
          }

          a {
            color: $color3;

            @media screen and (max-width: 1100px) {
              font-size: 14px;
            }

            &:hover {
              text-decoration: none;
              color: $color1;
            }
          }
        }

        .btns {
          display: flex;
          align-items: flex-start;

          gap: 20px;

          @media screen and (max-width: 1250px) {
            gap: 10px;
          }
        }
      }
    }
  }

  .hero {
    margin-top: 100px;

    .title-sm {
      position: relative;
      padding-left: 55px;
      color: $color6;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 40px;
        height: 5px;
        background-color: $color1;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    h1 {
      @media screen and (min-width: 1440px) {
        font-size: 70px;
      }

      @media screen and (max-width: 1100px) {
        font-size: 42px;
      }
    }
  }

  .brands {
    .row > * {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 100%;
      max-width: 225px;
    }
  }

  .contributors {
    .designations {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      margin-bottom: 70px;

      .designation-item {
        position: relative;
        display: flex;
        align-items: center;
        border-radius: 10px;
        padding: 20px;
        background-color: $color5;
        width: 33.33%;

        &::after {
          content: "";
          position: absolute;
          width: 28.68px;
          height: 28.68px;
          left: 50%;
          top: calc(100% - 16px);
          background: $color1;
          border-radius: 5px;
          transform: matrix(0.71, 0.71, -0.7, 0.71, 0, 0);
          opacity: 0;
        }

        h3 {
          @media screen and (max-width: 1220px) {
            font-size: 20px;
          }
        }

        &.active {
          background-color: $color1;

          * {
            color: #fff;
          }

          &::after {
            opacity: 1;
          }
        }

        h3 {
          margin-left: 20px;
        }
      }
    }

    .role-rights {
      .collapse {
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid $color17;

        &-title {
          padding: 0 0 20px;
          border: 0;
        }
      }
    }
  }

  .products {
    .product-card {
      background-color: $color9;
      border-radius: 10px;
      padding: 30px;
    }
  }

  .integrations {
    position: relative;
    overflow: hidden;

    .page-container {
      &::after {
        position: absolute;
        content: "";
        left: 50%;
        top: -270px;
        width: 540px;
        border-radius: 50%;
        height: 540px;
        background-color: $color4;
      }
    }

    .main {
      background-color: $color1;
      padding: 50px;
      border-radius: 10px;

      * {
        color: #fff;
      }

      .text {
        max-width: 421px;
      }

      .company-brands {
        max-width: 488px;
        // max-height: 192px;
        margin-left: 3rem;
        text-align: center;

        .row > * {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        img {
          @media screen and (max-width: 1023px) {
            transform: scale(0.8);
          }
        }
      }
    }
  }

  .switch-to-us {
    h4 {
      line-height: 1.5;
    }
  }

  .testimonials {
    overflow: hidden;

    .left {
      background-color: $color1;
      padding: 50px;
      border-radius: 10px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      @media screen and (max-width: 1400px) {
        padding-left: 30px;
        padding-right: 30px;
      }

      * {
        color: #fff;
      }

      .text {
        max-width: 420px;
      }

      .happy-card {
        background-color: $color4;
        border-radius: 10px;
        padding: 30px;

        @media screen and (max-width: 1300px) {
          padding-left: 20px;
          padding-right: 20px;
        }

        img.icon {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }

        .reviews {
          img {
            @media screen and (max-width: 1300px) {
              width: 16px;
            }
          }
        }
      }
    }

    .right {
      padding: 30px 30px 30px 0;
      background-color: $color9;
      border-radius: 10px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 100%;

      @media screen and (max-width: 1400px) {
        padding: 20px 20px 20px 0;
      }

      .main {
        padding: 40px 40px 40px 70px;
        background-color: #fff;
        height: 100%;

        @media screen and (max-width: 1400px) {
          padding: 30px 30px 30px 50px;
        }

        .slides {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .main-slides {
            flex-grow: 1;

            .comment {
              line-height: 1.75;
            }
          }
        }
      }
    }
  }

  .number-1 {
    .main-title {
      max-width: 1070px;
    }

    .swiper-wrap {
      position: relative;

      .swiper-slide {
        height: auto;
      }

      .btn-slider-nav {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        width: 50px;
        height: 50px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin: 0;
        }

        &.next {
          left: calc(100% + 20px);

          @media screen and (max-width: 1600px) {
            left: calc(100% + 10px);
          }

          @media screen and (max-width: 1200px) {
            left: calc(100% - 50px - 10px);
          }
        }

        &.prev {
          right: calc(100% + 20px);

          @media screen and (max-width: 1600px) {
            right: calc(100% + 10px);
          }

          @media screen and (max-width: 1200px) {
            right: calc(100% - 50px - 10px);
          }
        }
      }
    }

    .report-card {
      background-color: $color9;
      border-radius: 10px;
      text-align: center;
      border-radius: 10px;
      padding: 30px;
      height: 100%;
    }
  }

  .get-it {
    .main {
      background-color: $color1;
      border-radius: 10px;
      padding: 50px;

      * {
        color: #fff;
      }
    }
  }

  .landing-footer {
    background-color: $color9;
    margin-bottom: 0;

    .main {
      padding: 50px 0;

      h4 {
        margin-bottom: 20px;
      }

      .links {
        a {
          display: block;
          color: $color6;
          margin-bottom: 10px;

          &:hover {
            text-decoration: none;
            color: $color1;
          }
        }
      }
    }
  }
}

.landing-container .appbar .header .custom-form-control {
  margin-bottom: 0;
}

.Video_Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  .video-inner {
    width: 100%;
    min-height: 70%;
    max-width: 70%;
    display: flex;
    justify-content: center;
    position: relative;
  }
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable) {
  min-height: 200px;
}

.edit-meeting .form .uploader,
.create-meeting .form .uploader {
  background-color: #f9f9f9;
}
.project_overview_card .card-body {
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.button-unstyled {
  border: none;
  background-color: transparent;
}

button {
  cursor: pointer;
}

.chart-container canvas {
  width: 100% !important;
}
.hover-reason-card {
  transition: all ease 0.2s;
}
.reason-card:hover {
  cursor: pointer;
  .hover-reason-card {
    box-shadow: 0 25px 30px -13px rgb(40 40 40 / 40%),
      0 -5px 10px 0 rgb(40 40 40 / 40%);
  }
}

.backgroundRowColor:hover {
  background-color: #fdf4eb;
}
.hoverPointer {
  cursor: pointer;
}
.buttonHeight {
  min-width: 100px !important;
}

.laptopImage {
  border-radius: 1rem;
  border: 1px solid #fdf4eb;
}

.inputCapitalize {
  text-transform: capitalize;
}

.dailylog_table {
  select {
    padding: 14px 16px;
    width: 100%;
    cursor: pointer;
    font-size: 16px;
    margin-right: 17px;
    text-align: left;
    border-radius: 5px;
    border: 1px solid #dedee4;
    background-color: #f9f9f9;
    &:focus {
      outline: none;
    }
  }
}

.rc-time-picker .rc-time-picker-clear {
  right: 10px;
}
.rc-time-picker-panel-select {
  @extend %vertical-scrollbar;
}
.rc-time-picker-panel-select:first-child,
.rc-time-picker-panel-select:nth-child(2),
.rc-time-picker-panel-select:last-child {
  width: calc(100% / 3) !important;
}

.rc-time-picker-panel-inner {
  width: 100%;
}

.rc-time-picker-panel {
  width: 220px;
}

.dailylog_table td {
  min-width: 210px !important;
  vertical-align: text-bottom;
}

.no-margin {
  margin-bottom: -20px;
}

.td-lg {
  min-width: 500px;
}

.daily-log-upload {
  background: #ffffff;
  border: 1px solid #dedee4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 252.2px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #e79436;
  text-transform: uppercase;
  input {
    display: none;
  }
  img {
    display: block;
    margin-bottom: 20px;
  }
}

.pl-0 {
  padding-left: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dedee4;
  padding-bottom: 15px;
  padding-left: 1.5rem;
}

.daily-log {
  .card-nav {
    height: 100%;
    background: #f9f9f9;
  }
  label {
    cursor: pointer;
  }
}

.photo-dailylog {
  .helper {
    display: none;
  }
}

.log_image {
  width: 252px;
  height: 240px;
  border: none;
  background: transparent;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.log_image:hover {
  opacity: 0.8;
  background: #1f1f1f, url("");
}

.modal_buttons {
  button {
    background: none;
    border: none;
    margin-left: 26px;
  }
  img {
    width: 20px;
    height: 22px;
    display: block;
  }
}

.slider_section {
  margin-top: 20px;
  position: relative;
  img {
    display: block;
    width: 100%;
    height: 450px;
    margin-bottom: 30px;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #6e7077;
  }
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: normal;
    color: #1f1f1f;
    margin: 0;
  }
  .col-md-4 {
    margin-bottom: 30px;
  }
}

.photo_Modal {
  max-width: 750px;
}

.slider_nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 25px;
  z-index: 2;
  img {
    max-width: 50px;
    height: auto;
    cursor: pointer;
  }
  .left-arrow {
    transform: rotate(180deg);
  }
}

.create__btn {
  font-size: 33px !important;
  padding: 0 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 5px;
  font-weight: 400 !important;
  background: #fff2d9 !important;
  color: #e79436 !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 120px;
  min-height: 50px;
  font-size: 12px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 17px 0;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.swiper-button-disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.listing-container .table-container table thead tr th {
  min-width: 165px;
}

.fc-toolbar-chunk {
  display: flex;
  flex-direction: row-reverse;
  .fc-today-button.fc-button.fc-button-primary {
    background: #fff2d9;
    border-radius: 5px;
    width: 110px;
    height: 46px;
    border: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #e79436 !important;
  }
  .fc-button-group {
    margin-right: 10px;
    button {
      border: 1px solid #dedee4;
      background: transparent;
      margin-left: 10px !important;
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover,
      &:focus,
      &:active {
        background: #e79436 !important;
        border-color: #e79436 !important;
      }
    }
  }
}

.fc-header-toolbar.fc-toolbar {
  padding: 25px 20px;
}

.fc-col-header thead tr {
  height: 68px;
  th {
    vertical-align: middle;
    background: #ebebf0;
    border: 0.5px solid #dedee4;
    text-align: left;
    padding: 20px;
    a {
      color: #1f1f1f;
    }
  }
}

.fc-scrollgrid-sync-table tbody tr td {
  width: 160px;
  max-height: 130px !important;
  .fc-daygrid-day-frame.fc-scrollgrid-sync-inner .fc-daygrid-day-top {
    justify-content: flex-end;
    padding: 14px;
  }
}

.fc-scrollgrid-sync-table {
  td {
    &:not(.fc-day-other) a {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: #1f1f1f;
    }
  }
}

.fc-day-today {
  .fc-daygrid-day-number {
    width: 42px;
    height: 42px;
    background: #e79436;
    border-radius: 30px;
    color: #fff !important;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}
.fc-daygrid-day-events {
  width: 124px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
}
.fc-daygrid-event {
  background: #d9f1ff;
  width: 124px;
  border-radius: 5px;
  border: none;
  padding: 3px 8px;
  & div {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #1f1f1f;
  }
}

.fc-daygrid-event-harness {
  margin-top: 3px !important;
}
.fc-day-other a {
  color: #6e7077;
}
.fc .fc-day-other .fc-daygrid-day-top {
  opacity: 1;
}
.fc .fc-toolbar-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #1f1f1f;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
}

.fc-daygrid-dot-event {
  flex-wrap: wrap;
}
.fc-event-title.fc-sticky {
  position: relative;
  padding-left: 20px !important;
}
.fc-event-title.fc-sticky::after {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #1f1f1f;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
}

.invoice__details {
  label {
    font-weight: 400;
  }
  div {
    font-weight: 600;
  }
}

.project-heading {
  h4 {
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.5px;
  }
}

.invoice_details_table {
  thead td {
    padding: 20px 15px;
  }
  tbody td {
    font-size: 16px !important;
    padding-left: 15px !important;
  }
  .grand_total_row {
    background: rgba(231, 148, 54, 0.05) !important;
  }
}

.projectDescription {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 50px;
}

.notificationStatus {
  color: #e79436;
}

.notificationBadge span {
  position: absolute;
  /* transform: rotateZ(18deg); */
  top: -30%;
  right: -2%;
  background-color: #e79436;
  width: 15px;
  height: 16px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  letter-spacing: 0.1rem;
}

.notificationBack {
  color: #e79436 !important;
}

.notificationBack1 {
  border: 1px solid #e79436 !important;
  border-radius: 2rem !important;
}

.signatureDesciption {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.signatureTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.first_heading {
  display: flex;
}

.contractorInput {
  background: none !important;
  border: none !important;
  border-bottom: 2px solid black !important;
  border-radius: inherit !important;
}

.textInternalInput {
  background: none !important;
}

.comon_class {
  text-align: justify;
  text-justify: inter-word;
  line-height: 2;
}
.borderBottopm {
  border: 2px solid #dc3545 !important;
  border-radius: 5px !important;
}

.customLinkClass {
  padding: 0 !important;
  margin: 0 !important;
}
.customClassName {
  background-color: #e79436 !important;
}

.dropDownValues {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dropDownImage {
  padding-left: 5px !important;
  width: 50px !important;
  height: 40px !important;
  object-fit: cover !important;
}

.project-action-button {
  position: absolute;
  right: 25px;
  top: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.project-action-base {
  background: #f9f9f9;
  border-radius: 10px 10px 0px 0px;
}

.add-button-new {
  width: 35px;
  height: 35px;
  background: #e79436;
  border-radius: 5px;
  cursor: pointer;
}

.company-project {
  text-transform: capitalize;
}

// TABLE STYLEING

.sticky-column-header {
  position: sticky;
  right: 0;
  background-color: #ebebf0;
  z-index: 1; /* Ensure it's above other content */
}

.sticky-column-body {
  position: sticky;
  right: 0;
  background-color: white;
  z-index: 1; /* Ensure it's above other content */
}
