%vertical-scrollbar {
  /* width */
  &::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

%horizontal-scrollbar {
  /* width */
  &::-webkit-scrollbar {
    height: 2px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

%clearfix {
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

.clearfix {
  @extend %clearfix;
}

h1 {
  font-size: 52px;

  * {
    font-size: inherit;
  }
}

h2 {
  font-size: 36px;

  * {
    font-size: inherit;
  }
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

.ms-10 {
  margin-left: 10px !important;
}

.me-10 {
  margin-right: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.m-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.ms-15 {
  margin-left: 15px !important;
}

.me-15 {
  margin-right: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.m-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.ms-20 {
  margin-left: 20px !important;
}

.me-20 {
  margin-right: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.m-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.ps-20 {
  padding-left: 20px !important;
}

.pe-20 {
  padding-right: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.p-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.ms-30 {
  margin-left: 30px !important;
}

.me-30 {
  margin-right: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.ps-30 {
  padding-left: 30px !important;
}

.pe-30 {
  padding-right: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.p-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.ms-40 {
  margin-left: 40px !important;
}

.me-40 {
  margin-right: 40px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.m-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.ms-50 {
  margin-left: 50px !important;
}

.me-50 {
  margin-right: 50px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.m-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.mx-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.b-0 {
  border: none !important;
}

.m-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-1550-10 {
  @media screen and (max-width: 1550px) {
    font-size: 10px !important;
  }
}

.lh-1 {
  line-height: 1;
}

.lh-15 {
  line-height: 1.5;
}

.bx {
  border: 1px solid $color7;
  padding-top: 30px;
  padding-bottom: 30px;
  border-left: 0;
  border-right: 0;
}

a:not(.unstyled) {
  color: $color1;

  &:hover {
    text-decoration: underline;
  }
}

.text-light-1 {
  color: $color6;
}

.text-primary-1 {
  color: $color1 !important;
}

.text-secondary {
  color: $color2 !important;
}

.text-dark {
  color: $color3 !important;
}

.btn {
  background: transparent;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &.btn-arrow {
    background-color: $color5;
    width: 50px;
    height: 50px;
    display: block;

    &:hover {
      opacity: 0.8;
    }
  }

  &.btn-white,
  &.btn-cancel,
  &.btn-contained,
  &.btn-contained-2,
  &.btn-primary-text,
  &.btn-primary,
  &.btn-primary-outlined {
    font-size: 14px;
    font-weight: 700;
    max-height: 44px;
    height: 100%;
  }

  &.btn-white,
  &.btn-cancel,
  &.btn-contained,
  &.btn-primary,
  &.btn-primary-outlined {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 30px;
    padding-right: 30px;
  }

  &.btn-white {
    background-color: #fff;
    color: $color3 !important;

    &:hover {
      transform: translateY(-4px);
    }
  }

  &.btn-cancel {
    background-color: $color17;
    color: $color6;
    // border: 1px solid transparent;

    &:hover {
      background-color: $color24;
      // border-color: $color25;
      // color: $color3;
    }
  }

  &.btn-primary {
    background-color: $color1;
    color: #fff;

    &.sm {
      padding: 9px 12px;
      height: 30px;
      font-size: 12px;
    }

    &:hover {
      background-color: $color8;
    }
  }

  &.btn-auth,
  &.btn-primary-outlined {
    min-width: 150px;
    text-align: center;
  }

  &.btn-primary-text {
    color: $color1;
  }

  &.btn-contained-2,
  &.btn-primary-outlined {
    height: 46px;

    img {
      filter: invert(72%) sepia(96%) saturate(1088%) hue-rotate(328deg)
        brightness(90%) contrast(101%);
      margin-right: 10px;
    }
  }

  &.btn-primary-outlined {
    color: $color1;
    border: 1px solid $color1;
    display: inline-flex;
    align-items: center;

    &:hover {
      background-color: $color1;
      color: #fff;

      img {
        filter: invert(100%) sepia(5%) saturate(7500%) hue-rotate(269deg)
          brightness(117%) contrast(101%);
      }
    }
  }

  &.btn-transparent {
    color: $color6;
    font-weight: 700;
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }

  &.btn-contained {
    color: $color1;
    background-color: $color15;

    &:hover {
      background-color: $color16;
    }
  }

  &.btn-contained-2 {
    padding: 8px 20px;
    color: $color1;
    background-color: $color5;

    &:hover {
      background-color: $color16;
    }
  }

  &.no-min-width {
    min-width: unset;
  }
}

.input-card {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;

  .custom-form-control {
    margin-bottom: 0;
  }
  select {
    width: 100%;
    border: none;
    padding: 0;
    margin-top: 10px;
    margin-left: -4px;
    font-weight: 600;
    font-size: 14px;
    &:focus {
      outline: none;
    }
  }
}

.menu-card {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.menu-card:hover {
  background: #e79436;
}

.selected-menu-card {
  background: #e79436;
  // color: #E79436;
}

.menu-base-class {
  max-height: 600px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

input,
textarea {
  outline: none;
}

.custom-form-control {
  position: relative;
  margin-bottom: 30px;

  input[type="file"] {
    display: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .helper,
  .error-msg {
    margin-top: 5px;
    font-size: 11px;
  }

  .helper {
    color: $color6;
  }

  .error-msg {
    color: $color10;
  }

  .uploader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 26px;
    border-radius: 6px;
    border: 1px dashed $color7;
    cursor: pointer;

    &:hover {
      background-color: $color9;
      border-color: $color1;
    }

    img {
      margin-bottom: 16px;
    }

    .text {
      color: $color6;
      font-size: 13px;
    }
  }

  .search-icon,
  .pwd-icon {
    position: absolute;
  }

  .pwd-icon {
    top: calc(16px + 14px + 12px);
    right: 16px;
    cursor: pointer;
    width: 26px;
  }

  .search-icon {
    top: 12px;
    left: 16px;
  }

  .calender {
    position: absolute;
    right: 16px;
    top: calc(38px);
  }

  input,
  select {
    display: block;
    border-radius: 5px;
    border: 1px solid $color7;
    padding: 14px 16px;
    display: block;
    width: 100%;

    &::placeholder {
      color: $color6;
    }
    &:focus {
      outline: none;
    }
  }

  label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 12px;

    .info {
      cursor: pointer;
    }
  }

  input,
  textarea,
  select {
    display: block;
    border-radius: 5px;
    border: 1px solid $color7;
    padding: 14px 16px;
    display: block;
    width: 100%;
    background-color: transparent;

    &:focus {
      border-color: $color1;
    }

    &::placeholder {
      color: $color6;
    }

    &[type="search"] {
      padding-left: 44px;
      background-color: $color9;

      @media screen and (min-width: 991px) {
        width: 333px;
      }
    }
  }

  &.error {
    input {
      // border-color: $color10;
    }
  }
}

.custom-checkbox-container {
  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.with-reorder {
    padding-left: 50px;
  }

  &:hover input ~ .checkmark {
    background-color: $color7;
  }

  .reorder,
  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .checkmark {
    height: 16px;
    width: 16px;
    border: 1px solid $color7;
    border-radius: 4px;

    &::after {
      content: "";
      position: absolute;
      display: none;
    }

    &::after {
      left: 5px;
      top: 1px;
      width: 3px;
      height: 7px;
      border: solid white;
      border-width: 0px 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &.with-reorder {
    .checkmark {
      left: 24px;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $color1;
      border-color: transparent;

      &::after {
        display: block;
      }
    }
  }

  &:hover input:checked ~ .checkmark {
    background-color: $color8;
  }
}

.verification-inputs {
  display: flex;
  justify-content: start;
  align-items: center;

  input {
    max-width: 76px;
    text-align: center;
    margin-right: 10px;
    color: $color6;
    font-weight: 700;
    background-color: $color9;
  }
}

.custom-select-container {
  position: relative;

  .selected-text {
    padding: 14px 16px;
    width: 100%;
    cursor: pointer;
    font-size: 16px;
    margin-right: 17px;
    text-align: left;
    border-radius: 5px;
    border: 1px solid $color7;

    &.active {
      border-color: $color1;
    }
  }

  .selected-text::after {
    content: url("../vectors/select-arrow.svg");
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    transition: 0.4s ease;
  }

  .selected-text.active {
    &::after {
      transform: translateY(-50%) rotateX(180deg);
    }
  }

  ul {
    margin: 0;
    padding: 0;
    text-align: center;
    border-radius: 4px;
    left: 0;
    top: 100%;
    border: 1px solid $color7;
    z-index: 1;
    background-color: #fff;
  }

  .select-options {
    position: absolute;
    top: 100%;
    margin-top: 1px;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    max-height: 150px;
    overflow-y: auto;
    @extend %vertical-scrollbar;
  }

  li {
    list-style-type: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
    text-align: left;
  }

  li:last-child {
    border-bottom: none;
  }

  li:hover {
    // background: $color11;
    background-color: rgba(0, 0, 0, 0.025);
  }

  .show-dropdown-options {
    opacity: 1;
    visibility: visible;
  }

  .hide-dropdown-options {
    min-height: 0;
    opacity: 0;
    visibility: hidden;
  }

  &.no-border {
    &,
    .selected-text {
      border: none;
    }

    .selected-text {
      padding: 4px 0;
      font-size: 14px;

      &::after {
        right: 5px;
      }
    }
  }

  &.bold {
    .selected-text {
      font-weight: bold;
    }
  }
}

.mobile-number-input {
  display: flex;
  align-items: flex-start;
  border: 1px solid $color7;
  border-radius: 5px;

  .custom-form-control {
    margin: 0;
  }

  input {
    border: 0;
  }
  select {
    position: relative;
    margin: 0;
    border: none;
    width: auto;
    // height: 50px;
    font-weight: 500;
    display: inline-block;
    padding-left: 10px;
    &:focus {
      outline: none;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      background-color: $color7;
      height: 28px;
      width: 1px;
      transform: translateY(-50%);
    }
  }

  .custom-select-container {
    position: relative;
    margin: 0;
    border: none;
    width: 110px;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      background-color: $color7;
      height: 28px;
      width: 1px;
      transform: translateY(-50%);
    }

    .selected-text {
      font-weight: bold;
      border: none;
    }
  }
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid $color2;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  margin-left: auto;
  margin-right: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.table-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: clip;
  @extend %horizontal-scrollbar;

  table {
    .empty {
      td {
        height: 170px;
        text-align: center;
        font-size: 28px !important;
        color: $color7;
        font-weight: 700;
      }
    }
  }
}
.table-heading {
  line-height: 24px;
  padding-top: 18px;
}
.table-heading .headings {
  font-weight: 400;
  font-size: 14px;
  color: #6e7077;
}
.table-description {
  line-height: 24px;
  font-weight: 600;
  font-size: 16px;
}
.imageLoader {
  justify-content: flex-start;
  align-items: center;
}
.breadcrumb-item:not(:last-child)::after {
  font-size: 20px;
}
.dropdown {
  position: relative;
  cursor: pointer;
  padding-right: 24px;

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }

  .dropdown-items {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    width: 100%;
    padding-top: 6px;
    left: 0;
    font-weight: normal;
    z-index: 1;

    .dropdown-items-main {
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid $color7;

      li {
        a,
        &.clickable {
          padding: 12px 16px;
          display: block;
          color: $color3;

          &:hover {
            text-decoration: none;
            background-color: $color4;
            color: $color1;
          }
        }
      }
    }
  }

  &:hover {
    .arrow {
      transform: translateY(-50%) rotate(-180deg);
    }

    .dropdown-items {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.btn {
    padding: 14px 30px;
    border: 1px solid $color7;
    border-radius: 4px;
    font-weight: 700;
    color: $color6;
    display: inline-block;
    padding-right: 60px;
    font-size: 14px;
    .arrow {
      right: 30px;
    }

    li {
      font-size: 14px;
    }
  }
}

.react-datepicker__header {
  background-color: $color14;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: $color1;

  &:hover {
    background-color: $color8;
  }
}

.react-datepicker__triangle {
  transform: translate(-50%, 0) !important;
  left: 50% !important;
}

.react-datepicker__current-month {
  color: $color1;
}

.user-info {
  display: flex;
  align-items: center;

  .img-placeholder {
    width: 40px;
    height: 40px;
    background-color: $color7;
    border-radius: 50%;
    margin-right: 8px;
    text-align: center;
    line-height: 2.75;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
  }
}

.badge {
  height: 20px;
  padding: 0 14px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 10px;
  line-height: 2;
  display: inline-block;

  &.success {
    background-color: $color18;
    color: $color19;
  }
}

.tabs {
  display: flex;

  .tab {
    font-size: 13px;
    margin-right: 1rem;
    color: $color3;
    padding-bottom: 6px;
    border-bottom: 1px solid transparent;
    display: block;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    &:hover,
    &.active {
      border-bottom-color: $color1;
    }

    &.active {
      font-weight: bold;
    }
  }
}

.collapse {
  .collapse-title {
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid $color5;

    img.arrow {
      width: 12px;
      transition: 0.4s ease;
    }

    &.close {
      img.arrow {
        transform: rotate(-90deg);
      }
    }
  }

  .collapse-body {
    transition: unset;
  }
}

.card {
  border: 1px solid $color7;
  border-radius: 10px;

  .card-head,
  .card-body {
    padding: 20px;
  }

  .card-head {
    border-bottom: 1px solid $color7;
  }
  .horizntal-row {
    background: "#DEDEE4";
    width: 95%;
    height: 1px;
    margin: 15px;
    margin-bottom: 25px;
  }
  .horizntal-row:last-child {
    display: none;
  }
  .border-box {
    border-bottom: 2px solid #dedee4;
    border-bottom-width: 1px;
  }
  .border-box:last-child {
    border-bottom: none;
  }

  .card-body {
    display: flex;
    .card-right {
      padding: 30px 20px;
      width: calc(100% - 220px);

      .custom-form-control {
        margin: 0;
      }
    }
  }
}
.table-borderLess {
  background: none;
}
.description:last-child hr {
  display: none;
}

.breadcrumb {
  display: flex;
  align-items: center;

  &-item {
    font-size: 13px;

    &:not(:last-child) {
      position: relative;
      margin-right: 22px;

      &::after {
        position: absolute;
        right: -18px;
        top: 50%;
        transform: translateY(-50%);
        content: url(../vectors/breadcrumb-arrow.svg);
        pointer-events: none;
      }
    }
  }
}

.cal-nav {
  button {
    &.prev,
    &.next {
      background-color: transparent;
      border: 1px solid $color7;
      width: 44px;
      height: 44px;
      border-radius: 4px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &.prev {
      transform: rotate(90deg);
    }

    &.next {
      transform: rotate(-90deg);
    }
  }
}

.card-nav {
  width: 220px;
  border-right: 1px solid $color7;

  .nav-item {
    position: relative;
    padding: 12px;
    border-bottom: 1px solid $color7;
    background-color: $color9;
    font-size: 13px;
    cursor: pointer;
    font-weight: bold;

    &::after {
      position: absolute;
      top: 12px;
      right: 12px;
      content: url(../vectors/select-arrow.svg);
      transform: rotate(-90deg);
    }

    &.active {
      background-color: $color5;
      color: $color1;

      &::after {
        content: url(../vectors/select-arrow-yellow.svg);
      }
    }
  }
}

.image_upload input[type="file"] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  display: block;
}

.project .col-lg-6 {
  margin-bottom: 30px !important;
}

.project .custom-form-control {
  margin-bottom: 0;
}

.project .margin-bottom-30 {
  margin-bottom: 30px;
}

.image_upload .uploader {
  min-height: 115px;
  max-height: 115px;
}

.directory_select {
  label {
    font-size: 12px;
    line-height: 20px;
    color: #1f1f1f;
    margin-bottom: 5px;
    display: block;
  }
}

.empty {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin-top: 60px;
  text-align: center;
}

.phone_number_input {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  input {
    border: none;
  }
}

.number-input {
  height: 47px;
  // border: ;
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 6;
  backdrop-filter: blur(4px);

  &:not(.active) {
    opacity: 0;
    pointer-events: none;
  }
}

.custom-modal {
  position: fixed;
  padding: 10px 0;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 550px;
  border-radius: 20px;
  background-color: #fff;
  transform: translate(-50%, -50%);
  z-index: 7;

  .wrap {
    overflow-y: auto;
    max-height: 80vh;
    padding-left: 30px;
    padding-right: 30px;
    @extend %vertical-scrollbar;
  }

  &:not(.active) {
    opacity: 0;
    pointer-events: none;
    transform: translate(-50%, calc(-50% + 20px));
  }

  input,
  .selected-text,
  .uploader,
  select {
    background-color: $color9;
  }
}

hr {
  border: none;
  border-top: 1px solid $color7;
}

.c-pointer {
  cursor: pointer;
}

.e-calendar {
  max-width: unset;

  .e-content table {
    padding: 0;

    th,
    td {
      border: 0.5px solid rgba($color7, 0.5);
    }
  }

  .e-header.e-month,
  .e-bigger.e-small .e-header.e-month {
    padding: 20px;
  }

  .e-content thead {
    background-color: $color17;

    th {
      padding: 20px;
      text-align: left;
      color: $color3;
    }
  }

  .e-header .e-title,
  .e-bigger.e-small .e-calendar .e-header .e-title {
    font-size: 24px;
    font-weight: 700;
  }

  .e-content.e-month td {
    text-align: left;
    padding: 12px;
    padding-bottom: 8%;
  }

  .e-content td.e-today {
    span.e-day {
      color: $color1;
      border-color: $color1;
    }
  }

  .e-content td.e-today.e-selected span.e-day {
    background-color: $color1;
    border-color: $color1;
  }

  .e-content td.e-selected {
    span.e-day {
      background-color: $color1;
      font-weight: bold;
    }
    &:hover {
      span.e-day {
        background-color: $color8;
      }
    }
  }
  .e-header .e-icon-container .e-prev,
  .e-header .e-icon-container .e-next,
  .e-bigger.e-small .e-header .e-icon-container .e-prev,
  .e-bigger.e-small .e-header .e-icon-container .e-next {
    border: 1px solid $color7;
    border-radius: 4px;
    margin: 0 5px;
    transform: rotate(90deg);
  }

  .e-header .e-icon-container .e-prev {
    transform: rotate(-90deg);
  }

  .e-header .e-icon-container .e-next {
    transform: rotate(-90deg);
  }

  .e-btn.e-flat.e-primary,
  .e-css.e-btn.e-flat.e-primary {
    height: 46px;
    width: 110px;
    background-color: $color5;
    transition: 0.4s ease;
    margin-top: 18px;

    &:hover {
      background-color: $color16;
    }
  }
}

.wrapperClassName.rdw-editor-wrapper {
  border: 1px solid $color7;
  border-radius: 4px;
  min-height: 184px;
  background-color: $color9;

  .rdw-editor-toolbar {
    border: none;
    background-color: $color9;
  }
  .rdw-dropdown-wrapper,
  .rdw-option-wrapper {
    background-color: transparent;
  }

  .rdw-editor-main {
    padding: 14px 16px;
  }
}
.rc-time-picker {
  width: 100%;

  .rc-time-picker-clear {
    top: 50%;
    transform: translateY(-50%);
    right: 3rem;
  }
}
.rc-time-picker-input {
  height: 48px;
  font-weight: 400;
  color: $color3;
  font-size: 16px;
}

.select-wrapper {
  input {
    opacity: 0 !important;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-1s2u09g-control {
    background-color: #f4f4f8;
  }
}

.ck-blurred-ck .ck-content .ck- {
  min-height: 120px;
}

.edit-meeting .form {
  max-width: 650px;
}
.drop__down {
  background: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
  li {
    border-bottom: 1px solid #eee;
    padding: 10px;
    cursor: pointer;
    &:hover {
      background: $color7;
    }
    &:last-child {
      border: none;
    }
  }
}

.card-header {
  height: 60px;
  background-color: #f9f9f9;
}
.card-header .card-heading {
  padding-top: 20px;
  text-align: left;
}
.card-body {
  display: flex;
}
.project-heading {
  background-color: #fff2d9;
}
.withBackground {
  background: rgba(231, 148, 54, 0.05);
  max-width: 100%;
  height: 362px;
}
.project-label {
  font-size: 16px;
  font-weight: 600;
  color: #1f1f1f;
}
.eSignature-root {
  text-align: center;
  margin-top: 145px;
}
.eSignature-root h3 {
  padding: 10px;
  font-size: 36px;
  font-weight: 700;
}
.eSignature-root p {
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
}
.eSignature-root .btn {
  margin-top: 30px;
  padding: 14px 30px 15px;
}
.esignature-tab {
  color: #e79436;
}
.react-tabs__tab {
  background: none;
  flex: 1;
  padding-bottom: 18px;
  border-bottom: 1px solid #dedee4;
}
.react-tabs__tab--selected {
  border-style: none;
  border-bottom: 2px solid #e79436 !important;
  color: #e79436;
  width: 205px;
  font-size: 16px;
  font-weight: 700;
}
.react-tabs__tab:focus:after {
  display: none;
}
.react-tabs__tab-list {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#font-picker {
  width: 100%;
  height: 55px;
  border-radius: 5px;
  background: #f9f9f9;
  margin-top: 10px;
}
div[id^="font-picker"].expanded ul {
  background-color: #f9f9f9;
}
div[id^="font-picker"] ul li button.active-font {
  background: #f9f9f9;
}
div[id^="font-picker"] .dropdown-button {
  background: none;
}
div[id^="font-picker"] .dropdown-button:hover,
div[id^="font-picker"] .dropdown-button:focus {
  background: #f9f9f9;
}
div[id^="font-picker"] .dropdown-button:hover {
  background: none;
}
.apply-font {
  text-align: center;
  height: 120px;
  font-weight: 400;
  font-size: 56px;
}
.dropdown-button {
  background: none;
  margin-top: 12px;
}
.small-text {
  color: #6e7077;
  padding-left: 25px;
}
.text-date {
  padding-left: 25px;
}
.dropInput {
  cursor: pointer;
}

.drawings table tr th:nth-child(7) {
  min-width: 340px;
}

.uploader_multiple {
  flex-wrap: wrap;
}

.drawing-grid .col {
  max-width: 20%;
}

.consentModal {
  padding: 30px !important;
  text-align: left;
}
.consentModal p {
  font-size: 20px;
  font-weight: 500;
}

.dropdown_div {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  z-index: 2;
  width: 100px;
}
.dropdown_div > button {
  background: none;
  border: none;
  display: block;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: left;
  align-items: left;
  padding-left: 25px;
  padding-top: 10px;
  &:not(:last-child) {
    border-bottom: 1px solid rgb(248, 248, 248);
  }
  cursor: pointer;
  &:hover {
    background: $color13;
  }
}

.basic-single .select__indicator-separator {
  display: none;
}

.infinite-scroll {
  @extend %vertical-scrollbar;
  &::-webkit-scrollbar {
    width: 4px;
  }
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.btn-primary {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.Todo_heading {
  padding: 20px 19px;
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #6e7077;
    margin-top: 10px;
  }
}

// invoice card //
.card {
  border-radius: 5px;
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  border: none;
  margin-bottom: 30px;
}
.bordered_card:first-child .card {
  background: #fff2d9;
  border-left: 5px solid #e79436;
}
.bordered_card:nth-child(2) .card {
  background: #d9f1ff;
  border-left: 5px solid #346979;
}
.bordered_card:nth-child(3) .card {
  background: #fbe4e8;
  border-left: 5px solid #7e2a23;
}
.invoice-card {
  width: 100%;
  height: 150px;
  padding: 20px 20px 20px 30px;
}
.text-left {
  margin-top: 10px;
}
.m-b-20 {
  margin-top: 14px;
}
.cancelEdit.create__btn {
  background-color: #fbe4e8 !important;
}

.swiper-button-next,
.swiper-button-prev {
  display: none;
}
.g-total {
  padding: 17px;
}
.sales-person {
  margin-top: 30px;
  height: 47px;
}
.sales-person {
  .css-1s2u09g-control {
    height: 50px !important;
  }
  .css-1s2u09g-control {
    height: 50px !important;
  }
  .select__control {
    height: 50px !important;
  }
}
.project-detail-form {
  padding-top: 10px;
}
.items-slect {
  .select__control {
    height: 47px;
  }
}
.items-select {
  .css-1s2u09g-control {
    height: 47px;
  }
  .css-1s2u09g-control:hover {
    height: 47px;
  }
}
.discount-type-select {
  width: 100%;
  border: 1px solid #dedee4;
  border-radius: 5px;
  height: 47px;
  background-color: transparent;
  cursor: pointer;
}
.discount-type-select {
  .discount-option {
    cursor: pointer;
  }
}
.subTotal {
  max-height: "44px";
  padding: "10px";
  margin-top: "20px";
}
.item_button {
  font-size: 33px !important;
  padding: 0 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 5px;
  font-weight: 400 !important;
  background: none !important;
  color: #e79436 !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
}
.btn_addItem {
  float: right;
  margin-right: 45px;
}
.card-Notification {
  z-index: 5;
  position: absolute;
  top: 61px;
  right: 230px;
  width: 395px;
  background: white;
}
.view_allNOtofication {
  padding-top: 20px;
}
.view_all {
  padding-left: 50px;
  cursor: pointer;
  text-decoration: none !important;
}
.border_bottom {
  background: white;
  border-bottom: 1px solid #f6f6f6;
}
.noti_img {
  max-width: 50px;
  border-radius: 50%;
  border: 1px solid grey;
}
.notification-container:hover {
  // background-color: #f6f6f6;
  cursor: pointer;
}
.dot {
  height: 15px;
  width: 15px;
  background-color: #e79436;
  border-radius: 50%;
  display: inline-block;
  margin-top: 4px;
}
.notification-box {
  max-height: 400px;
  overflow: scroll;
  overflow-x: hidden;
  @extend %vertical-scrollbar;
}
.marknotification-topline {
  background: white;
  border-top: 1px solid #f6f6f6;
}
.mark-notification {
  text-decoration: none !important;
}
.text-limit-2-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.underline-notifications {
  color: #f6f6f6;
}

.changePaswordForm {
  margin: 40px;
}
.currentTab_one {
  width: 300px;
  color: #555;
}
.currentTab_one .active {
  color: #f6f4f1 !important;
  background-color: #e79436 !important;
  border-radius: 5px !important;
}
.mainTabClass {
  text-align: center !important;
  border-bottom: none;
}
.ChangePassword {
  border: 1px;
}
.number_input {
  border-radius: 5px;
}

// PhoneInput
.PhoneInput {
  border: 1px solid #dedee4 !important;
  border-radius: 7px !important;
  background: #f4f4f7 !important;
  padding: 0px 5px 0px 5px;
}
.PhoneInputInput {
  background: none !important;
  border: none !important;
}

.css-1xpauwj-Control {
  margin-bottom: 0px !important;
}
