$color1: #e79436;
$color2: #7e2a23;
$color3: #1f1f1f;
$color4: rgba(255, 255, 255, 0.2);
$color5: #fff2d9;
$color6: #6e7077;
$color7: #dedee4;
$color8: #ca822f;
$color9: #f9f9f9;
$color10: #dc3545;
$color11: rgba(110, 112, 119, 0.05);
$color12: rgba(110, 112, 119, 0.01);
$color13: rgba(110, 112, 119, 0.1);
$color14: rgba($color1, 0.05);
$color15: #fdf4eb;
$color16: rgba($color1, 0.2);
$color17: #ebebf0;
$color18: #e3fbe3;
$color19: #36b933;
$color20: #f84949;
$color21: #f6bb21;
$color22: #346979;
$color23: #b5b5bb;
$color24: rgba(52, 105, 121, 0.05);
$color25: #c1d8e1;
